import { createSelector } from '@ngrx/store';
import * as ListReducer from './electronic-tag.reducer';
import { selectFeatureState, State } from '../reducer';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { ElectronicTagDto } from '@ifhms/models/admin';

const selectState = createSelector(selectFeatureState, (state: State) => state.electronicTagSettings);

const selectLoading = createSelector(selectState, ListReducer.getLoading);

const selectLoaded = createSelector(selectState, ListReducer.getLoaded);

const selectElectronicTagSettings = createSelector(selectState, ListReducer.getElectronicTagSettings)

const selectSelectedFormatsList = createSelector(selectState, ListReducer.getSelectFormatsList);

const selectSettings = createSelector(selectState, ListReducer.getSettings);

const selectAvailableFormatsList = createSelector(selectState, ListReducer.getAvailableFormatsList);

const selectListItems = createSelector(selectAvailableFormatsList, updateListValues);

export function updateListValues(
  items: ElectronicTagDto[]
): SersiSelectListItem[] {
  return items.map((item: ElectronicTagDto) => {
    return {
      ...item,
      code: item.name,
      description: item.format
    } as SersiSelectListItem;
  });
}

export const ElectronicTagListSelectors = {
  selectState,
  selectLoaded,
  selectLoading,
  selectElectronicTagSettings,
  selectSelectedFormatsList,
  selectAvailableFormatsList,
  selectListItems,
  selectSettings
};
