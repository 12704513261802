import { NavListBaseItem } from '@ifhms/common/angular/features/navigation';

export enum AdminNavigationMenu {
  Company,
  Enterprise,
  Operation
}

export type CompanyMenuItemsFn = (companySlug: string) => NavListBaseItem[];
export type FeedlotMenuItemsFn = (
  companySlug: string,
  feedlotId: string,
  isDevelopmentMode?: boolean
) => NavListBaseItem[];
