import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Reports - Facilities] - ';

const get = createAction(`${NAMESPACE} Get`,
  props<{ operationId: string }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ items: SelectListItemWithTranslationDto[] }>()
);

const error = createAction(`${NAMESPACE} error`);

export const FacilitiesActions = {
  error,
  get,
  getSuccess
};
