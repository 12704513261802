import { SelectListItemDto } from '@ifhms/models/shared';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Reports - Pens] - ';

const get = createAction(`${NAMESPACE} Get`,
  props<{ levelId: string }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ items: SelectListItemDto[] }>()
);

const error = createAction(`${NAMESPACE} error`);

export const PensActions = {
  error,
  get,
  getSuccess
};
