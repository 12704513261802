import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { HashMap, TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Confirmation } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ah-utils-common-text-input-confirmation-dialog',
  templateUrl: 'common-text-input-confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonTextInputConfirmationDialogComponent implements OnInit {
  translationNamespace = 'components.dialog';

  dialogData: Confirmation;

  userInput = '';
  isConfirmDisabled = true;

  constructor(
    private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private translateService: TranslocoService
  ) {
    this.dialogData = this.config.data;
  }

  ngOnInit(): void {
    this.handleIndirectClose();
  }

  onInputChange(result: string): void {
    this.isConfirmDisabled = result.toLowerCase() !== this.getTranslation('dialog.confirmationInput');
  }

  onConfirm(): void {
    this.dialogRef.close(true);
    this.dialogData.accept?.();
  }

  onCancel(): void {
    this.dialogRef.close(false);
    this.dialogData.reject?.();
  }

  private handleIndirectClose(): void {
    // indirect dialog close with backdrop click, esc keypress, etc
    // should act as confirmation denied
    this.dialogRef.onClose
      .pipe(
        filter(isConfirmed => isConfirmed === void 0),
        untilDestroyed(this)
      )
      .subscribe(() => this.dialogData.reject?.())
  }

  private getTranslation(key: string, params?: HashMap): string {
    return this.translateService.translate(key, params);
  }

}