import { SersiSelectListItemDto } from '@ifhms/common/angular/data-access/report-api';

import { OperationAccessModule, OperationListItemDto, OperationTypeCode } from '@ifhms/models/admin';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as OperationsReducer from './operations.reducer';

export const selectFeatureState = createFeatureSelector<OperationsReducer.State>(OperationsReducer.FEATURE_NAME);

const selectState = createSelector(
  selectFeatureState,
  (state: OperationsReducer.State) => state
);

const selectCreating = createSelector(selectState, OperationsReducer.getCreating);
const selectCreated = createSelector(selectState, OperationsReducer.getCreated);

const selectLoading = createSelector(selectState, OperationsReducer.getLoading);
const selectLoaded = createSelector(selectState, OperationsReducer.getLoaded);

const selectOperations = createSelector(selectState, OperationsReducer.getAll);
const selectFilter = createSelector(selectState, OperationsReducer.getFilter);

const selectModuleFilters = createSelector(selectState, OperationsReducer.getModuleFilters);

const selectFeedlotOperations = createSelector(
  selectOperations,
  selectFilter,
  filterByText
);

const selectFilteredOperations = createSelector(selectFeedlotOperations, selectModuleFilters, filterByModules);

const selectSelectList = createSelector(
  selectOperations,
  (operations) => {
    if (!operations || !Array.isArray(operations)) {
      return [];
    }

    return operations.map((operation) => (<SersiSelectListItemDto>{
      id: operation.id,
      code: operation.code,
      description: operation.description

    }))
  }
)

function filterByText(operations: OperationListItemDto[] | null, filter: string): OperationListItemDto[] {
  if (!operations || !Array.isArray(operations)) {
    return [];
  }

  if (!filter) {
    return operations;
  }

  return operations.filter((operation) => {
    const description = operation.description || '';
    const isMatchingSearch = !filter || description.toLowerCase().includes(filter.toLowerCase());
    const isFeedlot = operation.operationTypeCode === OperationTypeCode.Feedlot;
    return isFeedlot && isMatchingSearch;
  });
}

function filterByModules(operations: OperationListItemDto[], filterModules: OperationAccessModule[]): OperationListItemDto[] {
  if (!operations || !Array.isArray(operations)) {
    return [];
  }

  if (!filterModules || !Array.isArray(filterModules) || filterModules.length === 0) {
    return operations;
  }

  return operations.filter((operation) => {
    return filterModules.some((module) => operation.operationAccess[module as keyof typeof OperationAccessModule]);
  });
}

export const OperationsSelectors = {
  selectCreating,
  selectCreated,
  selectLoading,
  selectLoaded,
  selectOperations,
  selectFeedlotOperations,
  selectFilteredOperations,
  selectFilter,
  selectState,
  selectSelectList
};
