import { APP_CONFIG_BASE } from './app-config-base';
import { AppConfig } from '@ifhms/admin/web/core/config';

export const APP_CONFIG_FMS_QA: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'qa',
  production: false,
  apiUrl: 'https://admin-api.qa.fms.feedlothealth.com/api',
  adminApiUrl: 'https://admin-api.qa.fms.feedlothealth.com/api',
  signalRUrl: 'https://admin-api.qa.fms.feedlothealth.com/PushNotification',
  showDevFeature: true,
  authServerUrl: 'https://identity.qa.fms.feedlothealth.com',
  feedlotAppUrl: 'https://feedlot.qa.fms.feedlothealth.com',
  migrationProgressSignalRUrl:
    'https://data-sync.qa.fms.feedlothealth.com/MigrationProgress',
  dataSyncApiUrl: 'https://data-sync.qa.fms.feedlothealth.com/api'
};
