import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ElectronicTagAvailableListDto,
  ElectronicTagDto,
  ElectronicTagSettingsDto
} from '@ifhms/models/admin';
import { Observable, of } from 'rxjs';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class FeedlotElectronicTagSettingsService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  getElectronicTagSettings(operationId: string): Observable<ElectronicTagSettingsDto> {
    return this.http.get<ElectronicTagSettingsDto>(
      `${this.apiUrl}/Operation/${operationId}/ElectronicTags/SelectListAllowedElectronicTags`
    );
  }

  getAvailableFormatsList(operationId: string): Observable<ElectronicTagAvailableListDto> {
    return this.http.get<ElectronicTagAvailableListDto>(
      `${this.apiUrl}/Operation/${operationId}/ElectronicTags/SelectList`
    );
  }

  update(operationId: string, electronicTagSettings: ElectronicTagSettingsDto): Observable<ElectronicTagSettingsDto> {
    return this.http.put<ElectronicTagSettingsDto>(
      `${this.apiUrl}/Operation/${operationId}/ElectronicTags/Update`, electronicTagSettings);
  }
}
