import { Injectable } from '@angular/core';

import { OperationFacade } from '@ifhms/admin/web/domain/state/operation';
import { ElectronicTagAvailableListDto, ElectronicTagDto, ElectronicTagSettingsDto } from '@ifhms/models/admin';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';

import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ElectronicTagSettingsActions } from './electronic-tag.actions';
import { FeedlotElectronicTagSettingsService } from '@ifhms/common/angular/data-access/admin-api';

@Injectable()
export class ElectronicTagSettingsEffects {
  translateScope = 'feedlot-settings-electronic-tag-management.state';

  constructor(
    private actions$: Actions,
    private electronicTagSettingsService: FeedlotElectronicTagSettingsService,
    private operationFacade: OperationFacade,
    private translateService: TranslocoService,
    public messageService: MessageService
  ) {
  }

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ElectronicTagSettingsActions.getElectronicTagSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([, feedlotId]) => {
        return this.electronicTagSettingsService.getElectronicTagSettings(feedlotId).pipe(
          map((electronicTagSettings: ElectronicTagSettingsDto) => {
            if (!electronicTagSettings) throw new Error();
            return ElectronicTagSettingsActions.getElectronicTagSettingsSuccess({ electronicTagSettings });
          }),
          catchError(() => of(ElectronicTagSettingsActions.error()))
        );
      })
    )
  );

  getAvailableFormatList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ElectronicTagSettingsActions.getAvailableFormats),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([, feedlotId]) => {
        return this.electronicTagSettingsService.getAvailableFormatsList(feedlotId).pipe(
          map((availableFormatsData: ElectronicTagAvailableListDto) => {
            if (!availableFormatsData) throw new Error();
            const availableFormatsList = availableFormatsData.nationalIdTypes
            return ElectronicTagSettingsActions.getAvailableFormatsSuccess({ availableFormatsList });
          }),
          catchError(() => of(ElectronicTagSettingsActions.error()))
        );
      })
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ElectronicTagSettingsActions.update),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([action, operationId]) => {
        return this.electronicTagSettingsService.update(operationId, action.electronicTagSettings).pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: this.getTranslation('updated-title'),
              detail: this.getTranslation('updated-message')
            });
          }),
          map((electronicTagSettings: ElectronicTagSettingsDto) => {
            if (!electronicTagSettings) throw new Error();
            return ElectronicTagSettingsActions.updateSuccess({ electronicTagSettings });
          }),
          catchError(() => of(ElectronicTagSettingsActions.error()))
        );
      })
    )
  );

  getTranslation(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }

}
