import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

import { scopeLoader } from '@common/angular/translation';
import { ReportApiSelectListService } from '@ifhms/common/angular/data-access/report-api';
import { SersiAngularUiSpinModule } from '@sersi/angular/ui/spin';

import { NgLetModule } from 'ng-let';
import { ReportsSelectListFacade } from './+state/facade';
import { FacilitiesEffects } from './+state/facilities/facilities.effects';
import { FacilitiesFacade } from './+state/facilities/facilities.facade';
import { FeedlotsEffects } from './+state/feedlots/feedlots.effects';
import { FeedlotsFacade } from './+state/feedlots/feedlots.facade';
import { LotBillingEffects } from './+state/lot-billing/lot-billing.effects';
import { LotBillingFacade } from './+state/lot-billing/lot-billing.facade';
import { LotSubGroupsEffects } from './+state/lot-subgroups/lot-subgroups.effects';
import { LotSubGroupsFacade } from './+state/lot-subgroups/lot-subgroups.facade';
import { LotsEffects } from './+state/lots/lots.effects';
import { LotsFacade } from './+state/lots/lots.facade';
import { NationalIdsEffects } from './+state/national-ids/national-ids.effects';
import { NationalIdsFacade } from './+state/national-ids/national-ids.facade';
import { PensEffects } from './+state/pens/pens.effects';
import { PensFacade } from './+state/pens/pens.facade';
import { PostMortemAnimalTagsEffects } from './+state/post-mortem-animal-tags/post-mortem-animal-tags.effects';
import { PostMortemAnimalTagsFacade } from './+state/post-mortem-animal-tags/post-mortem-animal-tags.facade';
import { ReconciliationDatesEffects } from './+state/reconciliation-dates/reconciliation-dates.effects';
import { ReconciliationDatesFacade } from './+state/reconciliation-dates/reconciliation-dates.facade';
import { FEATURE_NAME, reducer } from './+state/reducer';
import { RiskCategoriesEffects } from './+state/risk-catgeories/risk-catgeories.effects';
import { RiskCategoriesFacade } from './+state/risk-catgeories/risk-catgeories.facade';
import { TagsEffects } from './+state/tags/tags.effects';
import { TagsFacade } from './+state/tags/tags.facade';
import { WorkOrdersEffects } from './+state/work-orders/work-orders.effects';
import { WorkOrdersFacade } from './+state/work-orders/work-orders.facade';
import { ReportsPreviewButtonComponent, ReportsPreviewDialogComponent } from './components';
import { ReportHasOperationPermissionDirective } from './directive';
import { ReportsSharedMessageService } from './services';

// @ts-ignore
@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    SersiAngularUiSpinModule,
    TranslocoModule,
    PdfViewerModule,
    EffectsModule.forFeature([
      FeedlotsEffects,
      FacilitiesEffects,
      LotsEffects,
      LotSubGroupsEffects,
      NationalIdsEffects,
      PensEffects,
      ReconciliationDatesEffects,
      RiskCategoriesEffects,
      WorkOrdersEffects,
      TagsEffects,
      PostMortemAnimalTagsEffects,
      LotBillingEffects
    ]),
    StoreModule.forFeature(FEATURE_NAME, reducer),
    NgLetModule
  ],
  declarations: [
    ReportHasOperationPermissionDirective,
    ReportsPreviewButtonComponent,
    ReportsPreviewDialogComponent
  ],
  exports:[
    ReportHasOperationPermissionDirective,
    ReportsPreviewButtonComponent,
    ReportsPreviewDialogComponent
  ],
  providers: [
    ReportsSelectListFacade,
    FeedlotsFacade,
    FacilitiesFacade,
    LotsFacade,
    LotSubGroupsFacade,
    NationalIdsFacade,
    PensFacade,
    RiskCategoriesFacade,
    WorkOrdersFacade,
    ReconciliationDatesFacade,
    TagsFacade,
    PostMortemAnimalTagsFacade,
    LotBillingFacade,
    ReportApiSelectListService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'reports-shared',
        alias: 'reports-shared',
        loader: scopeLoader(
          (lang: string, root: string) => import(`./${root}/${lang}.json`)
        )
      }
    },
    ReportsSharedMessageService
  ]
})
export class IfhmsCommonAngularReportsSharedModule {}
