import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OperationModulesDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class ModuleEnablementService {
  constructor(private http: HttpClient,
    @Inject('apiUrl') private apiUrl: string) {
  }

  get(operationId: string): Observable<OperationModulesDto> {
    return this.http.get<OperationModulesDto>(`${this.apiUrl}/Operations/${operationId}/Modules`);
  }

  update(
    operationId: string,
    operationModulesDto: OperationModulesDto
  ): Observable<OperationModulesDto> {
    return this.http.put<OperationModulesDto>(`${this.apiUrl}/Operations/${operationId}/Modules`, operationModulesDto);
  }
}