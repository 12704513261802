import { Injectable } from '@angular/core';

import { OperationFacade } from '@ifhms/admin/web/domain/state/operation';
import { FeedlotSettingsService } from '@ifhms/common/angular/data-access/admin-api';
import { FeedlotArrivalSettingsDto } from '@ifhms/models/admin';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';

import { catchError, exhaustMap, map, of, tap, withLatestFrom } from 'rxjs';
import { ArrivalSettingsActions } from './arrival.actions';

@Injectable({
  providedIn: 'root'
})
export class ArrivalSettingsEffects {
  translateScope = 'feedlot-settings-arrival.state';

  constructor(
    private actions$: Actions,
    private feedlotSettingsService: FeedlotSettingsService,
    private operationFacade: OperationFacade,
    private translateService: TranslocoService,
    public messageService: MessageService
  ) {}

  getArrivalSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArrivalSettingsActions.getArrivalSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([, operationId]) =>
        this.feedlotSettingsService.getArrivalSettings(operationId).pipe(
          map((settings: FeedlotArrivalSettingsDto) =>
            ArrivalSettingsActions.getArrivalSettingsSuccess({ settings })
          ),
          catchError(() => of(ArrivalSettingsActions.error()))
        )
      )
    )
  );

  updateArrivalSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArrivalSettingsActions.updateArrivalSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([action, operationId]) =>
        this.feedlotSettingsService
          .updateArrivalSettings(operationId, action.settings)
          .pipe(
            tap(() => {
              this.messageService.add({
                severity: 'success',
                summary: this.getTranslation('success-title'),
                detail: this.getTranslation('saved-success-message')
              });
            }),
            map((settings: FeedlotArrivalSettingsDto) =>
              ArrivalSettingsActions.updateArrivalSettingsSuccess({ settings })
            ),
            catchError(() => of(ArrivalSettingsActions.error()))
          )
      )
    )
  );

  getTranslation(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }
}
