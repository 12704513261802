import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { NationalIdsActions } from './national-ids.actions';
import * as reducer from './national-ids.reducer';
import { NationalIdsSelectors } from './national-ids.selectors';

@Injectable()
export class NationalIdsFacade {

  loading$ = this.store.select(NationalIdsSelectors.selectLoading);
  loaded$ = this.store.select(NationalIdsSelectors.selectLoaded);
  items$ = this.store.select(NationalIdsSelectors.selectItems);

  constructor(private store: Store<reducer.State>) {
  }

  get(levelId: string, searchTerm?: number): void {
    this.store.dispatch(NationalIdsActions.get({ levelId, searchTerm }));
  }

}
