import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FeedlotSettingsEffects } from './+state/effects';
import { FeedlotSettingsFacade } from './+state/facade';
import { FEATURE_NAME, reducer } from './+state/reducer';
import { ArrivalSettingsEffects } from './+state/arrival/arrival.effects';
import { ArrivalSettingsFacade } from './+state/arrival/arrival.facade';
import { PostMortemSettingsEffects } from './+state/post-mortem/post-mortem.effects';
import { ReHandlingSettingsEffects } from './+state/re-handling/re-handling.effects';
import { PostMortemSettingsFacade } from './+state/post-mortem/post-mortem.facade';
import { ReHandlingSettingsFacade } from './+state/re-handling/re-handling.facade';
import { DefaultValuesSettingsEffects } from './+state/default-values/default-values.effects';
import { DefaultValuesSettingsFacade } from './+state/default-values/default-values.facade';
import { PricingSettingsFacade } from './+state/pricing/pricing.facade';
import { PricingSettingsEffects } from './+state/pricing/pricing.effects';
import { ElectronicTagSettingsEffects } from './+state/electronic-tag/electronic-tag.effects';
import { ElectronicTagSettingsFacade } from './+state/electronic-tag/electronic-tag.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([
      ArrivalSettingsEffects,
      PostMortemSettingsEffects,
      ReHandlingSettingsEffects,
      DefaultValuesSettingsEffects,
      PricingSettingsEffects,
      ElectronicTagSettingsEffects,
      FeedlotSettingsEffects
    ])
  ],
  providers: [
    ArrivalSettingsFacade,
    PostMortemSettingsFacade,
    ReHandlingSettingsFacade,
    DefaultValuesSettingsFacade,
    PricingSettingsFacade,
    ElectronicTagSettingsFacade,
    FeedlotSettingsFacade
  ]
})
export class IfhmsAdminWebDomainStateFeedlotSettingsModule {
}
