import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { TagsActions } from './tags.actions';
import * as reducer from './tags.reducer';
import { TagsSelectors } from './tags.selectors';

@Injectable()
export class TagsFacade {

  loading$ = this.store.select(TagsSelectors.selectLoading);
  loaded$ = this.store.select(TagsSelectors.selectLoaded);
  items$ = this.store.select(TagsSelectors.selectItems);

  constructor(private store: Store<reducer.State>) {
  }

  get(levelId: string, searchTerm?: string): void {
    this.store.dispatch(TagsActions.get({ levelId, searchTerm }));
  }

}
