import { Injectable } from '@angular/core';

import { getLoadedSelectItems } from '@common/angular/utils';
import { Store } from '@ngrx/store';

import { FacilitiesActions } from './facilities.actions';
import * as reducer from './facilities.reducer';
import { FacilitiesSelectors } from './facilities.selectors';

@Injectable()
export class FacilitiesFacade {

  loading$ = this.store.select(FacilitiesSelectors.selectLoading);
  loaded$ = this.store.select(FacilitiesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(FacilitiesSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  getByOperationId(operationId: string): void {
    this.store.dispatch(FacilitiesActions.get({ operationId }));
  }
}
