import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { RiskCategoriesActions } from './risk-catgeories.actions';
import * as reducer from './risk-catgeories.reducer';
import { RiskCategoriesSelectors } from './risk-catgeories.selectors';

@Injectable()
export class RiskCategoriesFacade {

  loading$ = this.store.select(RiskCategoriesSelectors.selectLoading);
  loaded$ = this.store.select(RiskCategoriesSelectors.selectLoaded);
  items$ = this.store.select(RiskCategoriesSelectors.selectItems);

  constructor(private store: Store<reducer.State>) {
  }

  get(levelId: string): void {
    this.store.dispatch(RiskCategoriesActions.get({ levelId }));
  }

}
