import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as FacilitiesReducer from './facilities/facilities.reducer';
import * as FeedlotsReducer from './feedlots/feedlots.reducer';
import * as LotSubGroupReducer from './lot-subgroups/lot-subgroups.reducer';
import * as LotsReducer from './lots/lots.reducer';
import * as NationalIdsReducer from './national-ids/national-ids.reducer';
import * as PensReducer from './pens/pens.reducer';
import * as PostMortemTagsReducer from './post-mortem-animal-tags/post-mortem-animal-tags.reducer';
import * as ReconciliationDatesReducer from './reconciliation-dates/reconciliation-dates.reducer';
import * as RiskCategoriesReducer from './risk-catgeories/risk-catgeories.reducer';
import * as TagsReducer from './tags/tags.reducer';
import * as WorkOrdersReducer from './work-orders/work-orders.reducer';
import * as LotBillingReducer from './lot-billing/lot-billing.reducer';

export const FEATURE_NAME = 'reports-select-lists';

export const selectFeatureState = createFeatureSelector<State>(FEATURE_NAME);

export const reducer: ActionReducerMap<State> = {
  facilities: FacilitiesReducer.reducer,
  feedlots: FeedlotsReducer.reducer,
  lots: LotsReducer.reducer,
  lotSubGroups: LotSubGroupReducer.reducer,
  nationalIds: NationalIdsReducer.reducer,
  pens: PensReducer.reducer,
  riskCategories: RiskCategoriesReducer.reducer,
  workOrders: WorkOrdersReducer.reducer,
  reconciliationDates: ReconciliationDatesReducer.reducer,
  tags: TagsReducer.reducer,
  postMortemAnimalTags: PostMortemTagsReducer.reducer,
  lotBillings: LotBillingReducer.reducer
};

export interface State {
  facilities: FacilitiesReducer.State;
  feedlots: FeedlotsReducer.State;
  lots: LotsReducer.State;
  lotSubGroups: LotSubGroupReducer.State;
  nationalIds: NationalIdsReducer.State;
  pens: PensReducer.State;
  riskCategories: RiskCategoriesReducer.State;
  workOrders: WorkOrdersReducer.State;
  reconciliationDates: ReconciliationDatesReducer.State;
  tags: TagsReducer.State;
  postMortemAnimalTags: PostMortemTagsReducer.State;
  lotBillings: LotBillingReducer.State;
}
