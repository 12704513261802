import { SelectListItemDto } from '@ifhms/models/shared';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Reports - Reconciliation Dates] - ';

const get = createAction(`${NAMESPACE} Get`,
  props<{ operationId: string }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ items: SelectListItemDto[] }>()
);

const error = createAction(`${NAMESPACE} error`);

export const ReconciliationDatesActions = {
  error,
  get,
  getSuccess
};
