import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ElectronicTagListSelectors } from './electronic-tag.selectors';
import { ElectronicTagSettingsActions } from './electronic-tag.actions';
import { ElectronicDefinedSettingsDto, ElectronicTagDto, ElectronicTagListsDto, ElectronicTagSettingsDto } from '@ifhms/models/admin';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

@Injectable()
export class ElectronicTagSettingsFacade {

  electronicTagSettings$: Observable<ElectronicTagSettingsDto> = this.store.select(
    ElectronicTagListSelectors.selectElectronicTagSettings
  );

  selectedFormatsList$: Observable<ElectronicTagDto[]> = this.store.select(
    ElectronicTagListSelectors.selectSelectedFormatsList
  );

  availableListItems$: Observable<SersiSelectListItem[]> = this.store.select(
    ElectronicTagListSelectors.selectListItems
  );

  availableFormatsList$: Observable<ElectronicTagDto[]> = this.store.select(
    ElectronicTagListSelectors.selectAvailableFormatsList
  );

  settings$: Observable<ElectronicDefinedSettingsDto> = this.store.select(
    ElectronicTagListSelectors.selectSettings
  );

  isLoading$: Observable<boolean> = this.store.select(
    ElectronicTagListSelectors.selectLoading
  );

  isLoaded$: Observable<boolean> = this.store.select(
    ElectronicTagListSelectors.selectLoaded
  );

  constructor(private readonly store: Store) {
  }

  getElectronicTagSettings(): void {
    this.store.dispatch(ElectronicTagSettingsActions.getElectronicTagSettings());
  }

  getAvailableFormatsList(): void {
    this.store.dispatch(ElectronicTagSettingsActions.getAvailableFormats());
  }

  updateListViewAdd(updatedFormatLists: ElectronicTagListsDto): void {
    this.store.dispatch(ElectronicTagSettingsActions.updateListViewAdd({ updatedFormatLists }));
  }

  updateListViewRemove(updatedFormatLists: ElectronicTagListsDto): void {
    this.store.dispatch(ElectronicTagSettingsActions.updateListViewRemove({ updatedFormatLists }));
  }

  updateSettings(electronicTagSettings: ElectronicTagSettingsDto): void {
    this.store.dispatch(ElectronicTagSettingsActions.update({ electronicTagSettings }));
  }

  reset(): void {
    this.store.dispatch(ElectronicTagSettingsActions.reset());
  }
}
