import { Injectable } from '@angular/core';

import { WorkOrderType } from '@ifhms/models/shared';
import { Store } from '@ngrx/store';
import { WorkOrdersActions } from './work-orders.actions';
import * as reducer from './work-orders.reducer';
import { WorkOrdersSelectors } from './work-orders.selectors';

@Injectable()
export class WorkOrdersFacade {

  loading$ = this.store.select(WorkOrdersSelectors.selectLoading);
  loaded$ = this.store.select(WorkOrdersSelectors.selectLoaded);
  items$ = this.store.select(WorkOrdersSelectors.selectItems);

  constructor(private store: Store<reducer.State>) {
  }

  get(levelId: string, workOrderType: WorkOrderType, showArchived?: boolean, searchTerm?: number): void {
    this.store.dispatch(WorkOrdersActions.get({ levelId, workOrderType, showArchived, searchTerm }));
  }

}
