import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as ArrivalSettingsReducer from './arrival/arrival.reducer';
import * as PostMortemSettingsReducer from './post-mortem/post-mortem.reducer';
import * as ReHandlingSettingsReducer from './re-handling/re-handling.reducer';
import * as DefaultValuesSettingsReducer from './default-values/default-values.reducer';
import * as PricingSettingsReducer from './pricing/pricing.reducer';
import * as ElectronicTagSettingsReducer from './electronic-tag/electronic-tag.reducer';

export const FEATURE_NAME = 'feedlot-settings';

export const selectFeatureState = createFeatureSelector<State>(FEATURE_NAME);

export const reducer: ActionReducerMap<State> = {
  arrivalSettings: ArrivalSettingsReducer.reducer,
  postMortemSettings: PostMortemSettingsReducer.reducer,
  reHandlingSettings: ReHandlingSettingsReducer.reducer,
  defaultValuesSettings: DefaultValuesSettingsReducer.reducer,
  pricingSettings: PricingSettingsReducer.reducer,
  electronicTagSettings: ElectronicTagSettingsReducer.reducer
};

export interface State {
  arrivalSettings: ArrivalSettingsReducer.State;
  postMortemSettings: PostMortemSettingsReducer.State;
  reHandlingSettings: ReHandlingSettingsReducer.State;
  defaultValuesSettings: DefaultValuesSettingsReducer.State;
  pricingSettings: PricingSettingsReducer.State;
  electronicTagSettings: ElectronicTagSettingsReducer.State;
}
