import { Injectable } from '@angular/core';
import { ReportApiSelectListService } from '@ifhms/common/angular/data-access/report-api';
import { SelectListItemDto } from '@ifhms/models/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { RiskCategoriesActions } from './risk-catgeories.actions';

@Injectable()
export class RiskCategoriesEffects {

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskCategoriesActions.get),
      exhaustMap((action) => {
        return this.reportApiSelectListService.getRiskCategoriesSelectList(
          action.levelId
        ).pipe(
          map((items: SelectListItemDto[]) => {
            if (!items) RiskCategoriesActions.error();
            return RiskCategoriesActions.getSuccess({ items });
          }),
          catchError(() => of(RiskCategoriesActions.error()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private reportApiSelectListService: ReportApiSelectListService
  ) {
  }
}
