import { Injectable } from '@angular/core';

import { OperationFacade } from '@ifhms/admin/web/domain/state/operation';
import { FeedlotSettingsService } from '@ifhms/common/angular/data-access/admin-api';
import { FeedlotDefaultValuesSettingsDto } from '@ifhms/models/admin';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';

import { catchError, exhaustMap, map, of, tap, withLatestFrom } from 'rxjs';
import { DefaultValuesSettingsActions } from './default-values.actions';

@Injectable({
  providedIn: 'root'
})
export class DefaultValuesSettingsEffects {
  translateScope = 'feedlot-settings-global-settings.state';

  constructor(
    private actions$: Actions,
    private feedlotSettingsService: FeedlotSettingsService,
    private operationFacade: OperationFacade,
    private translateService: TranslocoService,
    public messageService: MessageService
  ) {}

  getDefaultValuesSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultValuesSettingsActions.getDefaultValuesSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([, operationId]) =>
        this.feedlotSettingsService.getDefaultValuesSettings(operationId).pipe(
          map((settings: FeedlotDefaultValuesSettingsDto) =>
            DefaultValuesSettingsActions.getDefaultValuesSettingsSuccess({
              settings
            })
          ),
          catchError(() => of(DefaultValuesSettingsActions.error()))
        )
      )
    )
  );

  updateDefaultValuesSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultValuesSettingsActions.updateDefaultValuesSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([action, operationId]) =>
        this.feedlotSettingsService
          .updateDefaultValuesSettings(operationId, action.settings)
          .pipe(
            tap(() => {
              this.messageService.add({
                severity: 'success',
                summary: this.getTranslation('success-title'),
                detail: this.getTranslation('saved-success-message')
              });
            }),
            map((settings: FeedlotDefaultValuesSettingsDto) =>
              DefaultValuesSettingsActions.updateDefaultValuesSettingsSuccess({
                settings
              })
            ),
            catchError(() => of(DefaultValuesSettingsActions.error()))
          )
      )
    )
  );

  getTranslation(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }
}
