import { OperationAccessModule, OperationListItemDto } from '@ifhms/models/admin';
import { createReducer, on } from '@ngrx/store';

import { OperationsActions } from './operations.actions';

export const FEATURE_NAME = 'operations';

export interface State {
  operations: OperationListItemDto[] | null,
  companyId: string;
  creating: boolean;
  created: boolean;
  loading: boolean;
  loaded: boolean;
  filter: string;
  moduleFilters: OperationAccessModule[];
}

export const initialState: State = {
  operations: null,
  creating: false,
  created: false,
  loading: false,
  loaded: false,
  filter: '',
  companyId: '',
  moduleFilters: []
};

export const reducer = createReducer(
  initialState,

  on(OperationsActions.create,
    (state) => ({
      ...state,
      creating: true,
      created: false,
      loading: false,
      loaded: false
    })
  ),
  on(OperationsActions.createSuccess, (state, action) => ({
    ...state,
    operations: action.operations,
    creating: false,
    created: true,
    loading: false,
    loaded: true
  })),

  on(
    OperationsActions.get,
    (state) => ({
      ...state,
      creating: false,
      created: false,
      loading: true,
      loaded: false
    })
  ),

  on(OperationsActions.getSuccess, (state, action) => ({
    ...state,
    operations: action.operations,
    creating: false,
    created: false,
    loading: false,
    loaded: true,
    companyId: action.companyId
  })),

  on(OperationsActions.getSuccessCached, (state) => ({
    ...state,
    loading: false,
    loaded: true
  })),

  on(OperationsActions.error, (state) => ({
    ...state,
    creating: false,
    created: false,
    loading: false,
    loaded: false,
    companyId: ''
  })),

  on(OperationsActions.updateSelectedModuleFilters, (state, action) => ({
    ...state,
    moduleFilters: action.operationAccessModules
  })),

  on(OperationsActions.filter, (state, action) => ({
    ...state,
    filter: action.filter
  })),

  on(OperationsActions.resetFilters, (state) => ({
    ...state,
    filter: initialState.filter
  })),

  on(OperationsActions.reset, () => initialState)
);

export const getAll = (state: State): OperationListItemDto[] | null => state.operations;

export const getCreating = (state: State): boolean => state.creating;
export const getCreated = (state: State): boolean => state.created;

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;

export const getFilter = (state: State): string => state.filter;
export const getModuleFilters = (state: State): OperationAccessModule[] => state.moduleFilters;

