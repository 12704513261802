import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../reducer';
import * as reducer from './national-ids.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.nationalIds
);

const selectItems = createSelector(selectState, reducer.getAll);
const selectLoaded = createSelector(selectState, reducer.getLoaded);
const selectLoading = createSelector(selectState, reducer.getLoading);

export const NationalIdsSelectors = {
  selectItems,
  selectLoaded,
  selectLoading
};
