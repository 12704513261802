import { OperationalAdminAccess } from './operational-admin-access.enum';
import { OperationalPermissionsModuleAccess } from './operational-permissions-module-access.enum';
import { OperationalPermissionsRefData } from './operational-permissions-ref-data.enum';
import { OperationalPermissionsSettings } from './operational-permissions-settings.enum';
import { OperationalPermissionsUserManagement } from './operational-permissions-user-management.enum';

export type OperationalFeaturePermission =
  OperationalAdminAccess
  | OperationalPermissionsModuleAccess
  | OperationalPermissionsRefData
  | OperationalPermissionsSettings
  | OperationalPermissionsUserManagement;

export const OperationalFeaturePermission = {
  ...OperationalAdminAccess,
  ...OperationalPermissionsModuleAccess,
  ...OperationalPermissionsRefData,
  ...OperationalPermissionsSettings,
  ...OperationalPermissionsUserManagement
};
