import { OperationAccessModule, OperationCreateDto, OperationListItemDto } from '@ifhms/models/admin';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[OPERATIONS]';

const get = createAction(
  `${NAMESPACE} Get`,
  props<{ companyId: string, forceReload: boolean }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ operations: OperationListItemDto[], companyId: string }>()
);

const getSuccessCached = createAction(`${NAMESPACE} Get Success Cached`);

const filter = createAction(`${NAMESPACE} Filter`, props<{ filter: string }>());

const create = createAction(`${NAMESPACE} Create`,
  props<{
    operationCreateDto: OperationCreateDto
  }>()
);

const createSuccess = createAction(
  `${NAMESPACE} Create Success`,
  props<{ operations: OperationListItemDto[] }>()
);

const updateSelectedModuleFilters = createAction(
  `${NAMESPACE} Update Selected Module Filters`,
  props<{ operationAccessModules: OperationAccessModule[] }>()
);

const refresh = createAction(`${NAMESPACE} Refresh`);

const error = createAction(`${NAMESPACE} Error`);

const resetFilters = createAction(`${NAMESPACE} Reset Filters`);

const reset = createAction(`${NAMESPACE} Reset`);

export const OperationsActions = {
  create,
  createSuccess,
  get,
  getSuccess,
  getSuccessCached,
  refresh,
  filter,
  error,
  reset,
  resetFilters,
  updateSelectedModuleFilters
};
