import { Injectable } from '@angular/core';
import { ReportApiSelectListService } from '@ifhms/common/angular/data-access/report-api';
import { SelectListItemDto } from '@ifhms/models/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { WorkOrdersActions } from './work-orders.actions';

@Injectable()
export class WorkOrdersEffects {

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrdersActions.get),
      exhaustMap(( action  ) => {
        return this.reportApiSelectListService.getWorkOrderList(
          action.levelId, action.workOrderType, action.showArchived || false, action.searchTerm?.toString()
        ).pipe(
          map((items: SelectListItemDto[]) => {
            if (!items) WorkOrdersActions.error();
            return WorkOrdersActions.getSuccess({ items });
          }),
          catchError(() => of(WorkOrdersActions.error()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private reportApiSelectListService: ReportApiSelectListService
  ) {
  }
}
