import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  DecimalPipe
} from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import {
  CommonConfirmationDialogComponent,
  CommonDefaultDialogComponent,
  CommonDeleteConfirmationDialogComponent,
  CommonErrorDialogComponent,
  CommonInformationDialogComponent,
  CommonTextInputConfirmationDialogComponent
} from './components';
import {
  AhCommonCurrencyPipe,
  DateToStringPipe,
  EnumKeyPipe,
  FieldViewValuePipe,
  FieldViewValueUnitPipe,
  FilterDefinedPipe,
  HasValueInListPipe,
  HasRelatedActiveRoutePipe,
  JoinHashMapPipe,
  JoinPipe,
  MathMinPipe,
  NamePipe,
  NgSwitchMultiCasePipe,
  PhoneNumberPipe,
  SafeHtmlPipe,
  SelectListItemToCodePipe,
  SelectListItemToDescriptionPipe,
  SersiDatePipe,
  SersiPercentPipe
} from './pipes';
import { AhCommonDialogService } from './services';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, DialogModule, ButtonModule, FormsModule],
  declarations: [
    EnumKeyPipe,
    FieldViewValuePipe,
    FieldViewValueUnitPipe,
    FilterDefinedPipe,
    HasValueInListPipe,
    JoinPipe,
    HasRelatedActiveRoutePipe,
    JoinHashMapPipe,
    MathMinPipe,
    SafeHtmlPipe,
    SelectListItemToDescriptionPipe,
    SelectListItemToCodePipe,
    SersiDatePipe,
    AhCommonCurrencyPipe,
    SersiPercentPipe,
    NgSwitchMultiCasePipe,
    NamePipe,
    CommonConfirmationDialogComponent,
    CommonTextInputConfirmationDialogComponent,
    CommonDeleteConfirmationDialogComponent,
    CommonErrorDialogComponent,
    CommonInformationDialogComponent,
    CommonDefaultDialogComponent,
    DateToStringPipe,
    PhoneNumberPipe
  ],
  exports: [
    EnumKeyPipe,
    FieldViewValuePipe,
    FieldViewValueUnitPipe,
    FilterDefinedPipe,
    HasValueInListPipe,
    HasRelatedActiveRoutePipe,
    JoinPipe,
    JoinHashMapPipe,
    MathMinPipe,
    SafeHtmlPipe,
    SelectListItemToDescriptionPipe,
    SelectListItemToCodePipe,
    SersiDatePipe,
    AhCommonCurrencyPipe,
    SersiPercentPipe,
    NgSwitchMultiCasePipe,
    NamePipe,
    DateToStringPipe,
    PhoneNumberPipe
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    DialogService,
    AhCommonDialogService,
    FieldViewValuePipe,
    PhoneNumberPipe
  ]
})
export class CommonAngularUtilsModule {}
