import { Injectable } from '@angular/core';

import { filterSelectListByAttribute } from '@common/angular/utils';
import { WorkOrderType } from '@ifhms/models/shared';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import { FacilitiesFacade } from './facilities/facilities.facade';
import { FeedlotsFacade } from './feedlots/feedlots.facade';
import { LotSubGroupsFacade } from './lot-subgroups/lot-subgroups.facade';
import { LotsFacade } from './lots/lots.facade';
import { NationalIdsFacade } from './national-ids/national-ids.facade';
import { PensFacade } from './pens/pens.facade';
import { PostMortemAnimalTagsFacade } from './post-mortem-animal-tags/post-mortem-animal-tags.facade';
import { ReconciliationDatesFacade } from './reconciliation-dates/reconciliation-dates.facade';
import { RiskCategoriesFacade } from './risk-catgeories/risk-catgeories.facade';
import { TagsFacade } from './tags/tags.facade';
import { WorkOrdersFacade } from './work-orders/work-orders.facade';
import { LotBillingFacade } from './lot-billing/lot-billing.facade';

@Injectable()
export class ReportsSelectListFacade {

  feedlots$: Observable<SersiSelectListItem[]>;
  facilities$: Observable<SersiSelectListItem[]>;
  reportLots$: Observable<SersiSelectListItem[]>;
  lotSubGroups$: Observable<SersiSelectListItem[]>;
  nationalIds$: Observable<SersiSelectListItem[]>;
  pens$: Observable<SersiSelectListItem[]>;
  homePens$: Observable<SersiSelectListItem[]>;
  reconciliationDates$: Observable<SersiSelectListItem[]>;
  riskCategories$: Observable<SersiSelectListItem[]>;
  workOrders$: Observable<SersiSelectListItem[]>;
  tags$: Observable<SersiSelectListItem[]>;
  postMortemAnimalTags$: Observable<SersiSelectListItem[]>;
  lotBills$: Observable<SersiSelectListItem[]>;

  constructor(
    private feedlotsFacade: FeedlotsFacade,
    private facilitiesFacade: FacilitiesFacade,
    private lotsFacade: LotsFacade,
    private lotSubGroupsFacade: LotSubGroupsFacade,
    private nationalIdsFacade: NationalIdsFacade,
    private pensFacade: PensFacade,
    private reconciliationFacade: ReconciliationDatesFacade,
    private riskCategoriesFacade: RiskCategoriesFacade,
    private workOrdersFacade: WorkOrdersFacade,
    private tagsFacade: TagsFacade,
    private lotBillingFacade: LotBillingFacade,
    private postMortemAnimalTagsFacade: PostMortemAnimalTagsFacade
  ) {
    this.feedlots$ = this.feedlotsFacade.items$;
    this.facilities$ = this.facilitiesFacade.items$;
    this.reportLots$ = this.lotsFacade.items$;
    this.lotSubGroups$ = this.lotSubGroupsFacade.items$;
    this.nationalIds$ = this.nationalIdsFacade.items$;
    this.pens$ = this.pensFacade.items$;
    this.homePens$ = this.pensFacade.getHomeLocations();
    this.reconciliationDates$ = this.reconciliationFacade.items$;
    this.riskCategories$ = this.riskCategoriesFacade.items$;
    this.workOrders$ = this.workOrdersFacade.items$;
    this.tags$ = this.tagsFacade.items$;
    this.postMortemAnimalTags$ = this.postMortemAnimalTagsFacade.items$;
    this.lotBills$ = this.lotBillingFacade.items$;
  }

  getFeedLots(): void {
    this.feedlotsFacade.get();
  }

  getFacilities(operationId: string): void {
    this.facilitiesFacade.getByOperationId(operationId);
  }

  getLots(levelId: string, showArchived: boolean): void {
    levelId && this.lotsFacade.get(levelId, showArchived);
  }

  getLotSubGroups(levelId: string, showArchived?: boolean): void {
    levelId && this.lotSubGroupsFacade.get(levelId, showArchived);
  }

  getLotBillings(levelId: string, showArchived?: boolean, searchTerm?: number): void {
    this.lotBillingFacade.get(levelId, showArchived, searchTerm);
  }

  getNationalIds(levelId: string, searchTerm?: number): void {
    this.nationalIdsFacade.get(levelId, searchTerm);
  }

  getPens(levelId: string): void {
    levelId && this.pensFacade.get(levelId);
  }

  getReconciliationDates(operationId: string): void {
    this.reconciliationFacade.getByOperationId(operationId);
  }

  getRiskCategories(levelId: string): void {
    this.riskCategoriesFacade.get(levelId);
  }

  getWorkOrders(levelId: string, workOrderType: WorkOrderType, showArchived?: boolean, searchTerm?: number): void {
    this.workOrdersFacade.get(levelId, workOrderType, showArchived, searchTerm);
  }

  getTags(levelId: string, searchTerm?: string): void {
    this.tagsFacade.get(levelId, searchTerm);
  }

  getPostMortemAnimalTags(levelId: string, searchTerm?: string): void {
    this.postMortemAnimalTagsFacade.get(levelId, searchTerm);
  }

  getLotsSubGroupByLotId(lotId: string): Observable<SersiSelectListItem[]> {
    return filterSelectListByAttribute(
      this.lotSubGroupsFacade.items$,
      'lotId',
      lotId
    );
  }
}