import { Injectable } from '@angular/core';

import { filterSelectListByAttribute } from '@common/angular/utils';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import { PensActions } from './pens.actions';
import * as reducer from './pens.reducer';
import { PensSelectors } from './pens.selectors';

@Injectable()
export class PensFacade {

  loading$ = this.store.select(PensSelectors.selectLoading);
  loaded$ = this.store.select(PensSelectors.selectLoaded);
  items$ = this.store.select(PensSelectors.selectItems);

  constructor(private store: Store<reducer.State>) {
  }

  get(levelId: string): void {
    this.store.dispatch(PensActions.get({ levelId }));
  }

  getHomeLocations(): Observable<SersiSelectListItem[]> {
    return filterSelectListByAttribute(this.items$, 'penTypeCode', 'Home');
  }

}
