export interface ElectronicDefinedSettingsDto {
  useNationalIdSettings: boolean,
  blockNationalIdError: boolean,
  displayNationalIdWarningMessage: boolean
}

export enum ElectronicTagSettingsStatus {
  DisplayNationalIdWarningMessage = 1,
  BlockNationalIdError = 2
}

export interface ElectronicDefinedSettingsFormDto {
  useNationalIdSettings: boolean,
  selectedSettingOption: number | null
}
