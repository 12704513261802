import { createAction, props } from '@ngrx/store';
import {
  ElectronicTagDto,
  ElectronicTagListsDto,
  ElectronicTagSettingsDto
} from '@ifhms/models/admin';

const NAMESPACE = '[Electronic Tag Settings]';

const getElectronicTagSettings = createAction(`${NAMESPACE} Get Electronic Tag Settings`);

const getElectronicTagSettingsSuccess = createAction(
  `${NAMESPACE} Get Electronic Tag Settings Success`,
  props<{ electronicTagSettings: ElectronicTagSettingsDto }>()
);

const update = createAction(
  `${NAMESPACE} Update Electronic Tag Settings Update`,
  props<{ electronicTagSettings: ElectronicTagSettingsDto }>()
);

const updateSuccess = createAction(
  `${NAMESPACE} Update Electronic Tag Settings Update Success`,
  props<{ electronicTagSettings: ElectronicTagSettingsDto }>()
);

const getAvailableFormats = createAction(`${NAMESPACE} Get Available Format List`);

const getAvailableFormatsSuccess = createAction(
  `${NAMESPACE} Get Available Format List Success`,
  props<{ availableFormatsList: ElectronicTagDto[] }>()
);

const updateListViewAdd = createAction(
  `${NAMESPACE} Update Electronic Tag List View Update`,
  props<{ updatedFormatLists: ElectronicTagListsDto }>()
);

const updateListViewRemove = createAction(
  `${NAMESPACE} Remove Electronic Tag Format`,
  props<{ updatedFormatLists: ElectronicTagListsDto }>()
);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const ElectronicTagSettingsActions = {
  getElectronicTagSettings,
  getElectronicTagSettingsSuccess,
  getAvailableFormats,
  getAvailableFormatsSuccess,
  updateListViewAdd,
  updateListViewRemove,
  update,
  updateSuccess,
  error,
  reset
};
