import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CommonPermissionsService } from '@common/angular/permissions';
import { ContextService } from '@ifhms/common/angular/utils';
import { AppContextDto, ContextLevel } from '@ifhms/models/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription, distinctUntilChanged, of, switchMap } from 'rxjs';

@UntilDestroy()
@Directive({ selector: '[ifhmsReportHasOperationPermission]' })
export class ReportHasOperationPermissionDirective {
  private subscription?: Subscription;
  appContext$ = this.contextService.appContext$;
  contextLevel: ContextLevel;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: CommonPermissionsService,
    private contextService: ContextService
  ) {
  }

  @Input() set ifhmsReportHasOperationPermission(requiredPermission: string) {
    this.checkPermissionAccess(requiredPermission);
  }

  protected checkPermissionAccess(permission: string): void {
    this.subscription?.unsubscribe();
    this.subscription = this.appContext$.pipe(
      switchMap((appContext: AppContextDto) => {
        return appContext.contextLevel === ContextLevel.Operation
          ? this.permissionsService.hasPermission(permission)
          : of(true);
      })
    ).pipe(
      distinctUntilChanged(),
      untilDestroyed(this)
    )
      .subscribe(hasAccess => this.reRenderView(hasAccess));

  }

  private reRenderView(hasAccess: boolean): void {
    this.viewContainer.clear();
    if (hasAccess) this.viewContainer.createEmbeddedView(this.templateRef);
  }
}

