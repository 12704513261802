import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { LotsActions } from './lots.actions';
import * as reducer from './lots.reducer';
import { LotsSelectors } from './lots.selectors';

@Injectable()
export class LotsFacade {

  loading$ = this.store.select(LotsSelectors.selectLoading);
  loaded$ = this.store.select(LotsSelectors.selectLoaded);
  items$ = this.store.select(LotsSelectors.selectItems);

  constructor(private store: Store<reducer.State>) {
  }

  get(levelId: string, showArchived: boolean): void {
    this.store.dispatch(LotsActions.get({ levelId, showArchived }));
  }
}
