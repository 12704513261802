import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LotBillingActions } from './lot-billing.actions';
import * as reducer from './lot-billing.reducer';
import { LotBillingSelectors } from './lot-billing.selectors';

@Injectable()
export class LotBillingFacade {

  loading$ = this.store.select(LotBillingSelectors.selectLoading);
  loaded$ = this.store.select(LotBillingSelectors.selectLoaded);
  items$ = this.store.select(LotBillingSelectors.selectItems);

  constructor(private store: Store<reducer.State>) {
  }

  get(levelId: string, showArchived?: boolean, searchTerm?: number): void {
    this.store.dispatch(LotBillingActions.get({ levelId, showArchived, searchTerm }));
  }

}
