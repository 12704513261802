import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { PostMortemAnimalTagsActions } from './post-mortem-animal-tags.actions';
import * as reducer from './post-mortem-animal-tags.reducer';
import { PostMortemAnimalTagsSelectors } from './post-mortem-animal-tags.selectors';

@Injectable()
export class PostMortemAnimalTagsFacade {

  loading$ = this.store.select(PostMortemAnimalTagsSelectors.selectLoading);
  loaded$ = this.store.select(PostMortemAnimalTagsSelectors.selectLoaded);
  items$ = this.store.select(PostMortemAnimalTagsSelectors.selectItems);

  constructor(private store: Store<reducer.State>) {
  }

  get(levelId: string, searchTerm?: string): void {
    this.store.dispatch(PostMortemAnimalTagsActions.get({ levelId, searchTerm }));
  }

}
