import { BuildInfo } from '../interfaces';

export class BuildConfigHelper {
  private static instance: BuildConfigHelper;

  private buildInfo: BuildInfo | null = null;
  private buildInfoPromise: Promise<BuildInfo> | null = null;

  private readonly DEFAULT_BUILD_INFO: BuildInfo = {
    build_version_raw: null,
    build_version: 'unknown',
    build_branch: 'unknown'
  };

  private readonly DEFAULT_ENVIRONMENT_NAME = 'production';

  private appConfig: { environmentName: string };

  // Private constructor prevents direct instantiation
  private constructor(appConfig?: { environmentName: string }) {
    this.appConfig = appConfig || { environmentName: this.DEFAULT_ENVIRONMENT_NAME };
  }

  // Static method to control the access to the singleton instance
  public static getInstance(appConfig?: { environmentName: string }): BuildConfigHelper {
    if (!BuildConfigHelper.instance) {
      BuildConfigHelper.instance = new BuildConfigHelper(appConfig);
    } else if (appConfig) {
      // Optional: Update appConfig if provided after instance creation
      BuildConfigHelper.instance.appConfig = appConfig;
    }
    return BuildConfigHelper.instance;
  }

  async getBuildVersion(): Promise<string | null> {
    const buildInfo = await this.getBuildInfo();
    return buildInfo.build_version_raw;
  }

  async getBuildInfo(): Promise<BuildInfo> {
    if (this.buildInfo) return this.buildInfo;
    if (this.buildInfoPromise) return this.buildInfoPromise;

    this.buildInfoPromise = this.loadBuildInfo();
    return this.buildInfoPromise;
  }

  private async loadBuildInfo(): Promise<BuildInfo> {
    try {
      const buildInfo = await this.fetchBuildInfo();
      console.log('Build info loaded:', buildInfo);
      const processedBuildInfo: BuildInfo = {
        ...buildInfo,
        build_version_raw: buildInfo.build_version
      };
      this.buildInfo = processedBuildInfo;
      return processedBuildInfo;
    } catch (error) {
      return this.DEFAULT_BUILD_INFO;
    }
  }

  private async fetchBuildInfo(): Promise<BuildInfo> {

    // Fetch the build_info.json file using the Fetch API
    const response = await fetch('assets/build_info.json');

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  }
}
