import { SelectListItemDto } from '@ifhms/models/shared';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Reports - Lot SubGroups] - ';

const get = createAction(`${NAMESPACE} Get`,
  props<{ levelId: string, showArchived?: boolean }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ items: SelectListItemDto[] }>()
);

const error = createAction(`${NAMESPACE} error`);

export const LotSubGroupsActions = {
  error,
  get,
  getSuccess
};
