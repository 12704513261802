import { SelectListItemDto, WorkOrderType } from '@ifhms/models/shared';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Reports - Work Orders] - ';

const get = createAction(`${NAMESPACE} Get`,
  props<{ levelId: string, workOrderType: WorkOrderType, showArchived?: boolean, searchTerm?: number }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ items: SelectListItemDto[] }>()
);

const error = createAction(`${NAMESPACE} error`);

export const WorkOrdersActions = {
  error,
  get,
  getSuccess
};
