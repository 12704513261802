import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FeedlotsActions } from './feedlots.actions';
import * as reducer from './feedlots.reducer';
import { FeedlotsSelectors } from './feedlots.selectors';

@Injectable()
export class FeedlotsFacade {

  loaded$ = this.store.select(FeedlotsSelectors.selectLoaded);
  loading$ = this.store.select(FeedlotsSelectors.selectLoading);
  items$ = this.store.select(FeedlotsSelectors.selectItems);

  constructor(private store: Store<reducer.State>) {
  }

  get(): void {
    this.store.dispatch(FeedlotsActions.get());
  }
}
