import { Injectable } from '@angular/core';

import { getLoadedSelectItems } from '@common/angular/utils';
import { Store } from '@ngrx/store';

import { ReconciliationDatesActions } from './reconciliation-dates.actions';
import * as reducer from './reconciliation-dates.reducer';
import { ReconciliationDatesSelectors } from './reconciliation-dates.selectors';

@Injectable()
export class ReconciliationDatesFacade {

  loading$ = this.store.select(ReconciliationDatesSelectors.selectLoading);
  loaded$ = this.store.select(ReconciliationDatesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(ReconciliationDatesSelectors.selectItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {
  }

  getByOperationId(operationId: string): void {
    this.store.dispatch(ReconciliationDatesActions.get({ operationId }));
  }
}
