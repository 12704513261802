import { Injectable } from '@angular/core';
import { ReportApiSelectListService } from '@ifhms/common/angular/data-access/report-api';
import { ContextService } from '@ifhms/common/angular/utils';
import { ContextLevel, SelectListItemDto } from '@ifhms/models/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, of, withLatestFrom } from 'rxjs';
import { FeedlotsActions } from './feedlots.actions';

@Injectable()
export class FeedlotsEffects {

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeedlotsActions.get),
      withLatestFrom(this.contextService.appContext$),
      filter(([, appContext]) => appContext.contextLevel === ContextLevel.Company),
      exhaustMap(([, appContext]) => {
        return this.reportApiSelectListService.getOperationsSelectList(appContext.levelId).pipe(
          map((items: SelectListItemDto[]) => {
            if (!items) FeedlotsActions.error();
            return FeedlotsActions.getSuccess({ items });
          }),
          catchError(() => of(FeedlotsActions.error()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private contextService: ContextService,
    private reportApiSelectListService: ReportApiSelectListService
  ) {
  }
}
