export interface OperationAccessDto {
  hasFrm: boolean;
  hasPsmt: boolean;
  hasProcurement: boolean;
  hasOsvrHealthVisit: boolean;
  hasOsvrNutritionVisit: boolean;
  // hasChat: boolean;
}

export enum OperationAccessModule {
  hasFrm = 'hasFrm',
  hasPsmt = 'hasPsmt',
  hasProcurement = 'hasProcurement',
  hasOsvrHealthVisit = 'hasOsvrHealthVisit',
  hasOsvrNutritionVisit = 'hasOsvrNutritionVisit'
  // hasChat = 'hasChat'
}