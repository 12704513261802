import { APP_CONFIG_BASE } from './app-config-base';
import { AppConfig } from '@ifhms/admin/web/core/config';

export const APP_CONFIG_DEV: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'local',
  production: false,
  apiUrl: 'http://host.docker.internal:4500/api',
  adminApiUrl: 'http://host.docker.internal:4500/api',
  signalRUrl: 'http://host.docker.internal:4500/PushNotification',
  migrationProgressSignalRUrl:
    'http://host.docker.internal:5190/MigrationProgress',
  dataSyncApiUrl: 'http://host.docker.internal:5190/api',
  showDevFeature: true,
  authServerUrl: 'http://host.docker.internal:46000',
  feedlotAppUrl: 'http://host.docker.internal:4450'
};
