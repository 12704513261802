import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { stopEventPropagation } from '@common/angular/utils';
import { AbstractSelectType } from '../abstract-select-type/abstract-select-type.component';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'sersi-single-select',
  templateUrl: './single-select-type.component.html',
  styleUrls: ['./single-select-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SingleSelectTypeComponent
  extends AbstractSelectType
  implements AfterViewInit
{
  @ViewChild('select') select: NgSelectComponent;

  override defaultOptions = {
    props: {
      options: [],
      showClear: true,
      optionsLabel: 'DESCRIPTION',
      selectedItemLabel: 'DESCRIPTION',
      items: null,
      items$: null,
      excludeItems$: null,
      // When enabled, row height should be static for every item
      virtualScroll: true,
      bindValue: 'id',
      showInactiveItems: false,
      resetInvalidSelections: true,
      resetOnEmptyList: false,
      valueClassName: 'text-break-word',
      optionClassName: 'text-break-word',
      dropdownClassName: '',
      selectOnTab: true,
      autoSelectSingleRequiredOption: false
    }
  };

  ngAfterViewInit(): void {
    this.autoFocus();
  }

  private overlayClickListener: () => void;

  protected override attachCustomOverlayEvents(): void {
    if (!this.props['preventOverlayClickEvent']) return;

    setTimeout(() => {
      this.overlayClickListener = this.renderer.listen(
        this.panelElement,
        'click',
        stopEventPropagation
      );
    });
  }

  protected override detachCustomOverlayEvents(): void {
    if (!this.props['preventOverlayClickEvent']) return;
    setTimeout(() => this.overlayClickListener?.());
  }

  private autoFocus(): void {
    if (this.props['autoFocusInput']) this.select.focus();
  }
}
