import { SelectListItemDto } from '@ifhms/models/shared';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ReconciliationDatesActions } from './reconciliation-dates.actions';

export interface State extends EntityState<SelectListItemDto> {
  loading: boolean;
  loaded: boolean;
}

const adapter: EntityAdapter<SelectListItemDto> =
  createEntityAdapter<SelectListItemDto>({
    sortComparer: false
  });

const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false
});

export const reducer = createReducer(
  initialState,

  on(ReconciliationDatesActions.getSuccess, (state, { items }) =>
    adapter.setAll(items, {
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(ReconciliationDatesActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false
  }))
);

const { selectEntities, selectAll } = adapter.getSelectors();

export const getEntities = selectEntities;

export const getAll = selectAll;

export const getLoading = (state: State): boolean => state.loading;

export const getLoaded = (state: State): boolean => state.loaded;

