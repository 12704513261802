import { Injectable } from '@angular/core';

import { OperationFacade } from '@ifhms/admin/web/domain/state/operation';
import { FeedlotSettingsService } from '@ifhms/common/angular/data-access/admin-api';
import { FeedlotPostMortemSettingsDto } from '@ifhms/models/admin';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';

import { catchError, exhaustMap, map, of, tap, withLatestFrom } from 'rxjs';
import { PostMortemSettingsActions } from './post-mortem.actions';

@Injectable({
  providedIn: 'root'
})
export class PostMortemSettingsEffects {
  translateScope = 'feedlot-settings-post-mortem.state';

  constructor(
    private actions$: Actions,
    private feedlotSettingsService: FeedlotSettingsService,
    private operationFacade: OperationFacade,
    private translateService: TranslocoService,
    public messageService: MessageService
  ) {}

  getPostMortemSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostMortemSettingsActions.getPostMortemSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([, operationId]) =>
        this.feedlotSettingsService.getPostMortemSettings(operationId).pipe(
          map((settings: FeedlotPostMortemSettingsDto) =>
            PostMortemSettingsActions.getPostMortemSettingsSuccess({ settings })
          ),
          catchError(() => of(PostMortemSettingsActions.error()))
        )
      )
    )
  );

  updatePostMortemSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostMortemSettingsActions.updatePostMortemSettings),
      withLatestFrom(this.operationFacade.operationId$),
      exhaustMap(([action, operationId]) =>
        this.feedlotSettingsService
          .updatePostMortemSettings(operationId, action.settings)
          .pipe(
            tap(() => {
              this.messageService.add({
                severity: 'success',
                summary: this.getTranslation('success-title'),
                detail: this.getTranslation('saved-success-message')
              });
            }),
            map((settings: FeedlotPostMortemSettingsDto) =>
              PostMortemSettingsActions.updatePostMortemSettingsSuccess({
                settings
              })
            ),
            catchError(() => of(PostMortemSettingsActions.error()))
          )
      )
    )
  );

  getTranslation(key: string): string {
    return this.translateService.translate(`${this.translateScope}.${key}`);
  }
}
