import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { LotSubGroupsActions } from './lot-subgroups.actions';
import * as reducer from './lot-subgroups.reducer';
import { LotSubGroupsSelectors } from './lot-subgroups.selectors';

@Injectable()
export class LotSubGroupsFacade {

  loading$ = this.store.select(LotSubGroupsSelectors.selectLoading);
  loaded$ = this.store.select(LotSubGroupsSelectors.selectLoaded);
  items$ = this.store.select(LotSubGroupsSelectors.selectItems);

  constructor(private store: Store<reducer.State>) {
  }

  get(levelId: string, showArchived?: boolean): void {
    this.store.dispatch(LotSubGroupsActions.get({ levelId, showArchived }));
  }
}
