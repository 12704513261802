export enum FrmPermissionsSettings {
  SettingsAdgSettingsView = 'frmPermission.canViewAdgSettings',
  SettingsAdgSettingsEdit = 'frmPermission.canEditAdgSettings',
  SettingsZeroScaleReminderView = 'frmPermission.canViewZeroScaleReminderSettings',
  SettingsZeroScaleReminderEdit = 'frmPermission.canEditZeroScaleReminderSettings',
  SettingsTargetWeightView = 'frmPermission.canViewTargetWeightSettings',
  SettingsTargetWeightEdit = 'frmPermission.canEditTargetWeightSettings',

  SettingsPricingSettingsView = 'frmPermission.canViewPricingSettings',
  SettingsPricingSettingsEdit = 'frmPermission.canEditPricingSettings',
  SettingsDefaultValuesView = 'frmPermission.canViewDefaultValueSettings',
  SettingsDefaultValuesEdit = 'frmPermission.canEditDefaultValueSettings',
  SettingsArrivalView = 'frmPermission.canViewArrivalSettings',
  SettingsArrivalEdit = 'frmPermission.canEditArrivalSettings',
  SettingsReHandlingView = 'frmPermission.canViewRehandlingSettings',
  SettingsReHandlingEdit = 'frmPermission.canEditRehandlingSettings',
  SettingsPostMortemView = 'frmPermission.canViewPostMortemSettings',
  SettingsPostMortemEdit = 'frmPermission.canEditPostMortemSettings',
  SettingsLabourChargesView = 'frmPermission.canViewLabourCharges',
  SettingsLabourChargesEdit = 'frmPermission.canEditLabourCharges',
  SettingsProcedureChargesView = 'frmPermission.canViewProcedureCharges',
  SettingsProcedureChargesEdit = 'frmPermission.canEditProcedureCharges',
  SettingsPenChargesView = 'frmPermission.canViewPenCharges',
  SettingsPenChargesEdit = 'frmPermission.canEditPenCharges',
  SettingsOtherFeesView = 'frmPermission.canViewOtherFees',
  SettingsOtherFeesEdit = 'frmPermission.canEditOtherFees',
  SettingsCustomFieldsManagementView = 'frmPermission.canViewCustomFields',
  SettingsCustomFieldsManagementEdit = 'frmPermission.canEditCustomFields',
  SettingsTagView = 'frmPermission.canViewTagSettings',
  SettingsTagEdit = 'frmPermission.canEditTagSettings',
  SettingsElectronicTagView = 'frmPermission.canViewElectronicTags',
  SettingsElectronicTagEdit = 'frmPermission.canEditElectronicTags',
  SettingsSortGroupView = 'frmPermission.canViewSortGroups',
  SettingsSortGroupEdit = 'frmPermission.canEditSortGroups',
  SettingsSortGroupTemplatesView = 'frmPermission.canViewSortGroupTemplates',
  SettingsSortGroupTemplatesEdit = 'frmPermission.canEditSortGroupTemplates',
  SettingsCCIAView = 'frmPermission.canViewCciaSettings',
  SettingsCCIAEdit = 'frmPermission.canEditCciaSettings'
}
