import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { APP_CONFIG, BuildConfigService } from '@common/angular/config';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { Observable, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);
  private buildConfigService = inject(BuildConfigService);
  private appConfig = inject(APP_CONFIG);

  getTranslation(lang: string): Observable<Translation> {
    return this.buildConfigService.getBuildVersion().pipe(
      switchMap(buildVersion => {
        buildVersion ||= new Date().getTime().toString();
        const config : any = this.appConfig;
        const baseUrl = config.isPackaged ? './assets' : '/assets';

        return this.http.get<Translation>(`${baseUrl}/i18n/${lang}.json?${buildVersion}`);

      })
    )
  }
}
