import { AuthConfig } from 'angular-oauth2-oidc';

export const AUTH_FLOW_CONFIG: Partial<AuthConfig> = {
  responseType: 'code',
  scope: 'openid profile AnimalHealth.Fms.Admin.App.All AnimalHealth.DataSync.Service.All offline_access',
  timeoutFactor: 0.3,
  silentRefreshTimeout: 1000 * 60, // 60 seconds
  requireHttps: false,
  checkOrigin: false,
  useSilentRefresh: true,
  silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`
};
